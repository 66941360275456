/* eslint-disable react/prop-types */
import { useEffect, useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Layout from "../../../layouts";
import Container from "../../../components/Container";
import Link from "../../../components/Links/LinkOnPage";
import LinkOnResource from "../../../components/Links/LinkOnResource";
import Heading from "../../../components/Heading";
import { createClassName } from "../../../helpers";

import "./style.scss";

const cn = createClassName("success-payment");

const CONFETTI_CDN =
  "https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.3/dist/confetti.browser.min.js";

const getConfetti = () => {
  const script = document.createElement("script");
  document.body.appendChild(script);
  script.async = true;
  script.onload = () => {
    // eslint-disable-next-line no-undef
    confetti({
      particleCount: 100,
      spread: 75,
      angle: 100,
      origin: { y: 0.8 },
    });
  };
  script.src = CONFETTI_CDN;
};

const SuccessPurchase = ({ location }) => {
  const { t } = useTranslation("purchase");

  const rootRef = useRef(null);

  const { orderNumber, count, date, label, totalCost } = useMemo(() => {
    const params = new URLSearchParams(location.search);

    return {
      orderNumber: params.get("orderNumber"),
      date: params.get("date"),
      label: params.get("label"),
      totalCost: params.get("totalCost"),
      count: params.get("count"),
    };
  }, [location]);

  useEffect(() => {
    getConfetti();
    rootRef.current.style.opacity = 1;
  }, []);

  return (
    <Layout title={t("success-title")}>
      <Container>
        <div ref={rootRef} className={cn("root")}>
          <Heading level={3}>{t("success-message")}</Heading>
          <div className={cn("message-container")}>{t("check-email")}</div>
          <div className={cn("order-details-container")}>
            <span>{t("order-number", { orderNumber })}</span>
            <span>{t("order-date", { date })}</span>
            <span>{t("total-cost", { totalCost })}</span>
            <span>{t("license", { label })}</span>
            <span>{t("license-count", { count })}</span>
          </div>
          <div className={cn("support-container")}>
            <span>{t("activation-support")}</span>
            <LinkOnResource
              className={cn("support-link")}
              href="/support/"
              isVolume
              color="white"
              withoutLocale={false}
            >
              {t("common:go_to_support")}
            </LinkOnResource>
          </div>
          <span>
            <Trans i18nKey="purchase:contact-us">
              If you have any questions, please
              <Link href="/contacts/" color="primary-main">
                contact us
              </Link>
              .
            </Trans>
          </span>
        </div>
      </Container>
    </Layout>
  );
};

export default SuccessPurchase;
